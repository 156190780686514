<template>
  <div @touchmove.prevent>
     <scroll
        ref="scroll"
        class="scroll"
        :data="readingList"
        :listen-scroll="true"
        @scroll="scroll"
        :pull-down-refresh="{threshold: 30, stop: 0}"
        @pullingDown="pullDownRefresh"
        :pull-up-load="true"
        @pullingUp="pullUpPush"
      >
        <div>
          <div class="pull-down-wrapper">
            <div class="pull-down-text" v-show="!isPullingDown">下拉即可刷新...</div>
            <div class="pull-down-text" v-show="isPullingDown">释放即可刷新...</div>
          </div>
          <back class="back" back-url="/my-promotion"></back>
          <div class="mainsTitel">
            <div class="itemTiel">序号</div>
            <div  class="itemTiel">姓名</div>
            <div  class="itemTiel">时间</div>
          </div>
          <div class="mainsconst">
              <div class="mainsconst2" v-for="(item,index) in readingList" :key="index">
                <div >{{index+1}}</div>
                <div class="name" ><span v-if="item.user">{{item.user.c_user_wxname}}</span><span v-else>匿名用户</span></div>
                <div >{{item.create_time}}</div>
              </div>

          </div>

        </div>
     </scroll>
  </div>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import Back from '@/components/back/Back'
import { getSpreadUser } from '@/api/promotion.js'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'userGuid'
    ])
  },
  components: {
    Scroll,
    Back
  },
  data () {
    return {
      readingList: [],
      isPullingDown: false,
      showDivider: false, // 加载全部显示标记

      from: {
        c_user_guid: '',
        page: 1, // 分页器
        limit: 16 // 分页显示条目
      }
    }
  },
  mounted () {
    this._getSpreadUser()
  },
  methods: {
    scroll (pos) {
      if (pos.y > 30) {
        this.isPullingDown = true
      } else if (pos.y <= 0) {
        this.isPullingDown = false
      }
    },
    pullDownRefresh () {
      this.from.page = 1
      this.readingList.splice(0, this.readingList.length)
      this.showDivider = false
      this._getSpreadUser()
    },
    pullUpPush () {
      if (this.showDivider) {
        return true
      }
      this.from.page++
      console.log(this.from)
      this._getSpreadUser()
    },
    _getSpreadUser () {
      this.from.c_user_guid = this.userGuid
      // this.from.c_user_guid = 'e4235151-75cc-1814-7b5b-619fdac100b8'
      getSpreadUser(this.from).then(res => {
        const { code, data } = res
        if (code === 200) {
          this.readingList = this.readingList.concat(data.data)
          if (this.readingList.length === data.total) {
            this.showDivider = true
          }
        }
      })
    }
  }
}
</script>

<style lang='stylus' scoped>
.scroll{
    position absolute
    top 0
    left 0
    right 0
    bottom 97px
    bottom calc(constant(safe-area-inset-bottom) + 97px)
    bottom calc(env(safe-area-inset-bottom) + 97px)
    overflow hidden
    background-color: #F5F9F6;

   .pull-down-wrapper{
      display flex
      justify-content center
      align-items center
      width 750px
      height 70px
      margin-top -70px
    }

    .pull-down-text{
        line-height normal
        font-size 22px
        color rgba(153, 153, 153, 1)
      }
    .mainsTitel{
        width auto;
        margin: 0px 20px;
        background: #FFFFFF;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        display: flex;
        justify-content: space-between
        padding 10px 20px;
        margin-top: 10px;
        .itemTiel{
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #333333;
        }
        border-bottom: 1px solid #000000;
      }
      .mainsconst{
          width auto;
          margin: 0px 20px;
          background: #FFFFFF;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;

          .mainsconst2{
              display: flex;
              justify-content: space-between
              padding 15px 20px;
              font-size: 26px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #6A6A6A;

              .name{
                width 40%;
                line-height: 40px;
              }

          }
      }
  }

</style>
